import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get, pick } from 'lodash';
import { Row, Col, Form } from 'reactstrap';
import { venomHistory } from 'client/utils/history/venom-history';
import { PUB_STATES_NEW, PUB_STATES_USED } from 'client/constants/pub-states';
import { TAB_IDS } from 'client/site-modules/shared/constants/search-by-module';
import {
  getInventoryTypeParams,
  buildCurrentUrlWithRandomParam,
} from 'site-modules/shared/components/search-by-module/search-by-module-utils';
import { DELIVERY_TYPE, MAKE, MODEL } from 'site-modules/shared/constants/allowed-seo-srp-request-params';
import {
  srpLinkBuilderWithParamsConversion,
  srpLinkBuilder,
  generateSrpLinkMakeModel,
  generateSrpLinkNewMakeModel,
} from 'site-modules/shared/utils/srp-link-constructor';
import { MakeDropdown } from 'site-modules/shared/components/vehicle-dropdowns/make-dropdown';
import { ModelDropdown } from 'site-modules/shared/components/vehicle-dropdowns/model-dropdown';
import { SearchByZipAndGo } from 'site-modules/shared/components/search-by-tabs/search-by-tabs-components/search-by-zip-and-go';
import {
  MAKE_MODEL_TAB_RADIOS,
  SearchByBuyOnline,
} from 'site-modules/shared/components/search-by-tabs/search-by-tabs-components/search-by-buy-online';
import { Select } from 'site-modules/shared/components/inventory/select/select';

export class SearchMakeModelTab extends Component {
  static propTypes = {
    onSelectionUpdate: PropTypes.func,
    tracking: PropTypes.shape({}),
    isUsed: PropTypes.bool,
    isCpo: PropTypes.bool,
    useFlatUrl: PropTypes.bool,
    hasCorePageRedirect: PropTypes.bool,
    disableMakeToggle: PropTypes.bool,
    disableModelToggle: PropTypes.bool,
    make: PropTypes.shape({
      slug: PropTypes.string,
      name: PropTypes.string,
    }),
    model: PropTypes.shape({
      slug: PropTypes.string,
      name: PropTypes.string,
    }),
    location: PropTypes.shape({}),
    isSmallContainer: PropTypes.bool,
    showRadioButtons: PropTypes.bool,
    btnColor: PropTypes.string,
  };

  static defaultProps = {
    tracking: {},
    onSelectionUpdate: null,
    isUsed: false,
    isCpo: false,
    useFlatUrl: false,
    hasCorePageRedirect: false,
    disableMakeToggle: false,
    disableModelToggle: false,
    make: null,
    model: null,
    location: {},
    isSmallContainer: false,
    showRadioButtons: false,
    btnColor: undefined,
  };

  static getDerivedStateFromProps({ make, model }, { isValuesRecievedFromProps }) {
    return (make || model) && !isValuesRecievedFromProps
      ? {
          make,
          model,
          isValuesRecievedFromProps: !isValuesRecievedFromProps,
        }
      : null;
  }

  state = {
    isValuesRecievedFromProps: false,
    isZipChangeInProgress: false,
    isSubmitPending: false,
    make: null,
    model: null,
    buyOnlineSelector: null,
  };

  onZipChangeInProgress = ({ isZipChangeInProgress }) => {
    this.setState({ isZipChangeInProgress }, () => {
      const { isSubmitPending } = this.state;
      if (!isZipChangeInProgress && isSubmitPending) {
        this.redirect();
      }
    });
  };

  setMake = make =>
    this.setState({ make, model: null }, () => {
      const { onSelectionUpdate } = this.props;
      if (onSelectionUpdate) {
        onSelectionUpdate('make', pick(make, ['slug', 'name']));
        onSelectionUpdate('model', null);
      }
    });

  setModel = model =>
    this.setState({ model }, () => {
      const { onSelectionUpdate } = this.props;
      if (onSelectionUpdate) onSelectionUpdate('model', pick(model, ['slug', 'name']));
    });

  redirect = () => {
    const { location } = this.props;
    history.replaceState({}, '', buildCurrentUrlWithRandomParam(location));
    venomHistory.push(this.buildHref());
  };

  buildHref = () => {
    const { hasCorePageRedirect, isUsed, isCpo, useFlatUrl } = this.props;
    const { buyOnlineSelector } = this.state;
    const makeSlug = get(this.state.make, 'slug');
    const modelSlug = get(this.state.model, 'slug');

    if (hasCorePageRedirect && makeSlug) {
      return modelSlug ? `/${makeSlug}/${modelSlug}/` : `/${makeSlug}/`;
    }

    if (useFlatUrl && makeSlug && modelSlug) {
      return isUsed ? generateSrpLinkMakeModel(makeSlug, modelSlug) : generateSrpLinkNewMakeModel(makeSlug, modelSlug);
    }

    if (buyOnlineSelector === MAKE_MODEL_TAB_RADIOS.ONLINE) {
      return srpLinkBuilderWithParamsConversion(
        {
          [MAKE]: makeSlug,
          [MODEL]: modelSlug,
          ...getInventoryTypeParams(isUsed, isCpo),
        },
        true
      );
    }

    return srpLinkBuilder({
      [MAKE]: makeSlug,
      [MODEL]: modelSlug,
      ...getInventoryTypeParams(isUsed, isCpo),
      ...(buyOnlineSelector === MAKE_MODEL_TAB_RADIOS.IN_STORE && { [DELIVERY_TYPE]: isUsed ? 'local' : null }),
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { isZipChangeInProgress } = this.state;
    this.setState({ isSubmitPending: true });

    if (!isZipChangeInProgress) {
      this.redirect();
    }
  };

  toggleBuyOnline = ({ target: { value } }) => {
    const selector = value === this.state.buyOnlineSelector ? null : value;
    this.setState({ buyOnlineSelector: selector });
  };

  render() {
    const { make, model, buyOnlineSelector } = this.state;
    const {
      tracking,
      isUsed,
      disableMakeToggle,
      disableModelToggle,
      isSmallContainer,
      showRadioButtons,
      btnColor,
    } = this.props;
    const pubStates = isUsed ? PUB_STATES_USED : PUB_STATES_NEW;

    return (
      <Form autoComplete="off" onSubmit={this.handleSubmit} className="search-by-make-model-tab">
        <Row>
          {showRadioButtons && (
            <Col xs={12} className="mb-0">
              <SearchByBuyOnline
                name="search-by-make-model"
                buyOnlineSelector={buyOnlineSelector}
                buyOnlineToggle={this.toggleBuyOnline}
              />
            </Col>
          )}

          <Col
            xs={12}
            md={isSmallContainer ? 6 : 4}
            xl={4}
            className={classNames('mb-1', { 'mb-md-0': !isSmallContainer, 'mb-xl-0': isSmallContainer })}
          >
            <MakeDropdown
              value={make && make.slug}
              onChange={this.setMake}
              isFirstOptionDisabled={disableMakeToggle}
              toggle={disableMakeToggle ? 'Select Make' : 'Any Make'}
              pubStates={pubStates}
              className=""
              labelText="Select Make"
              isLabelHidden
              data-tracking-id={tracking.makeSelectTrackingId}
              selectComponent={Select}
              size="lg"
            />
          </Col>
          <Col
            xs={12}
            md={isSmallContainer ? 6 : 4}
            xl={4}
            className={classNames('mb-1', { 'mb-md-0': !isSmallContainer, 'mb-xl-0': isSmallContainer })}
          >
            <ModelDropdown
              make={make}
              value={model && model.name}
              onChange={this.setModel}
              toggle={disableModelToggle ? 'Select Model' : 'Any Model'}
              isFirstOptionDisabled={disableModelToggle}
              pubStates={pubStates}
              className=""
              labelText="Select Model"
              isLabelHidden
              data-tracking-id={tracking.modelSelectTrackingId}
              size="lg"
              selectComponent={Select}
            />
          </Col>
          <Col xs={12} md={isSmallContainer ? 6 : 4} xl={4} className="d-flex">
            <SearchByZipAndGo
              onZipChangeInProgress={this.onZipChangeInProgress}
              tracking={tracking}
              facetName={TAB_IDS.MAKE_MODEL_TAB}
              btnColor={btnColor}
            />
          </Col>
        </Row>
      </Form>
    );
  }
}
