import { get } from 'lodash';
import { DISPLAY_PRICE } from 'client/site-modules/shared/constants/allowed-seo-srp-request-params';
import { SEARCH_BY_TYPE_OPTIONS } from 'client/site-modules/shared/components/search-by-tabs/search-by-type-tab/search-by-type-tab';

export function convertParams(name, currentValue) {
  if (name === 'vehicleTypePriceRange' || name === 'priceRange') {
    return { [DISPLAY_PRICE]: get(currentValue, 'range') };
  }

  if (name === 'vehicleType') {
    const option = SEARCH_BY_TYPE_OPTIONS.find(({ value }) => value === currentValue);
    const filterType = get(option, 'filterType');
    return { [filterType]: currentValue };
  }

  return { [name]: get(currentValue, 'name') };
}
