import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { SelectComponent } from 'site-modules/shared/components/vehicle-dropdowns/select-component';

export class UpToPaymentDropdown extends Component {
  static propTypes = {
    onSelect: PropTypes.func.isRequired,
    selectProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
      ),
      disabled: PropTypes.bool,
    }),
    wrapperClassNames: PropTypes.string,
    selectClassNames: PropTypes.string,
  };

  static defaultProps = {
    selectProps: {},
    wrapperClassNames: 'd-flex justify-content-start align-items-center',
    selectClassNames: '',
  };

  static getDerivedStateFromProps({ maxPayment }, { isValuesRecievedFromProps }) {
    return maxPayment && !isValuesRecievedFromProps
      ? {
          maxPayment,
          isValuesRecievedFromProps: !isValuesRecievedFromProps,
        }
      : null;
  }

  state = {
    isValuesRecievedFromProps: false,
    maxPayment: 0,
  };

  setPaymentRange = value => (value ? `*-${value}` : '');

  handleSelect = (selected = {}) =>
    this.setState(
      () => ({ maxPayment: selected.value }),
      () => this.props.onSelect({ range: this.setPaymentRange(selected.value), maxPayment: selected.value })
    );

  render() {
    const { selectProps, selectClassNames, wrapperClassNames } = this.props;
    const { maxPayment } = this.state;

    return (
      <div className={wrapperClassNames}>
        <div>Up to</div>
        <div className="mx-1 price-select-wrapper">
          <SelectComponent
            value={maxPayment}
            toggle="Max $"
            onChange={this.handleSelect}
            name="max-price-select"
            className={classNames('min-price-select', selectClassNames)}
            {...selectProps}
          />
        </div>
        <div>per month</div>
      </div>
    );
  }
}
