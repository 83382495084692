import React from 'react';
import { RadioList } from 'site-modules/shared/components/radio-list/radio-list';
import PropTypes from 'prop-types';
import { TrackingConstant } from 'client/tracking/constant';
import { EventToolbox } from 'client/utils/event-toolbox';

import './search-by-buy-online.scss';

export const MAKE_MODEL_TAB_RADIOS = {
  IN_STORE: 'Buy In-store',
  ONLINE: 'Buy Online',
};
export const MAKE_MODEL_TAB_RADIOS_SUB = {
  IN_STORE: 'Local listings available in-store',
  ONLINE: 'Home delivery, 7-day returns',
};

export function SearchByBuyOnline({ name, buyOnlineSelector, buyOnlineToggle }) {
  const handleSelection = e => {
    buyOnlineToggle(e);
    const isUnselected = e.target.value === buyOnlineSelector;
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_START,
      event_data: {
        action_name: TrackingConstant.ACTION_TOOL_SELECT,
        subaction_name: isUnselected ? TrackingConstant.BUY_ONLINE_UNSELECT : TrackingConstant.BUY_ONLINE_SELECT,
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
        creative_id: 'edm-entry-inventory-search',
        value: e.target.value,
      },
    });
  };
  const radioToRender = [
    {
      title: MAKE_MODEL_TAB_RADIOS.IN_STORE,
      text: MAKE_MODEL_TAB_RADIOS_SUB.IN_STORE,
    },
    {
      title: MAKE_MODEL_TAB_RADIOS.ONLINE,
      text: MAKE_MODEL_TAB_RADIOS_SUB.ONLINE,
    },
  ];

  return (
    <div id={`${name}-buy-online-container`} className="d-flex search-by-buy-online">
      <RadioList
        name={`${name}-radio-buy-online`}
        list={radioToRender}
        checked={buyOnlineSelector}
        onChange={buyOnlineToggle}
        onClick={handleSelection}
        formClassName="mb-1_25 mb-md-0_5 py-0_5 py-md-0"
        formGroupClassName="d-inline-block mr-1_5"
        textClassName="medium"
        checkmarkClassName="pos-a"
      />
    </div>
  );
}

SearchByBuyOnline.propTypes = {
  name: PropTypes.string.isRequired,
  buyOnlineSelector: PropTypes.string,
  buyOnlineToggle: PropTypes.func.isRequired,
};

SearchByBuyOnline.defaultProps = {
  buyOnlineSelector: null,
};
