import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TabContent, TabPane } from 'reactstrap';
import { venomHistory } from 'client/utils/history/venom-history';
import {
  srpLinkBuilderWithParamsConversion,
  srpLinkBuilder,
} from 'client/site-modules/shared/utils/srp-link-constructor';
import { TAB_IDS } from 'client/site-modules/shared/constants/search-by-module';
import { PRICE_TAB_IDS } from 'site-modules/shared/components/search-by-module/search-by-module-constants';
import {
  buildCurrentUrlWithRandomParam,
  getInventoryTypeParams,
} from 'site-modules/shared/components/search-by-module/search-by-module-utils';
import { RadioList } from 'client/site-modules/shared/components/radio-list/radio-list';
import {
  MAKE_MODEL_TAB_RADIOS,
  SearchByBuyOnline,
} from 'site-modules/shared/components/search-by-tabs/search-by-tabs-components/search-by-buy-online';
import { DELIVERY_TYPE } from 'site-modules/shared/constants/allowed-seo-srp-request-params';
import { SearchByVehiclePriceForm } from './search-by-vehicle-price-form';
import { SearchByLoanPaymentForm } from './search-by-loan-payment-form';
import { SearchByLeasePaymentForm } from './search-by-lease-payment-form';

import './search-by-price-tab.scss';

export class SearchByPriceTab extends Component {
  static propTypes = {
    onSelectionUpdate: PropTypes.func,
    tracking: PropTypes.shape({}),
    priceRange: PropTypes.shape({
      range: PropTypes.string,
      minPrice: PropTypes.number,
      maxPrice: PropTypes.number,
    }),
    loanPriceRange: PropTypes.shape({
      range: PropTypes.string,
      maxPayment: PropTypes.number,
    }),
    leasePriceRange: PropTypes.shape({
      range: PropTypes.string,
      maxPayment: PropTypes.number,
    }),
    isUsed: PropTypes.bool,
    isCpo: PropTypes.bool,
    location: PropTypes.shape({}),
    isSmallContainer: PropTypes.bool,
    showRadioButtons: PropTypes.bool,
  };

  static defaultProps = {
    onSelectionUpdate: null,
    tracking: {},
    priceRange: {},
    loanPriceRange: {},
    leasePriceRange: {},
    isUsed: false,
    isCpo: false,
    location: {},
    isSmallContainer: false,
    showRadioButtons: false,
  };

  state = {
    activeTab: PRICE_TAB_IDS.PRICE,
    buyOnlineSelector: null,
  };

  redirect = params => {
    const { buyOnlineSelector } = this.state;
    const { location, isUsed, isCpo } = this.props;

    let link = srpLinkBuilder({
      ...getInventoryTypeParams(isUsed, isCpo),
      ...params,
      ...(buyOnlineSelector === MAKE_MODEL_TAB_RADIOS.IN_STORE && { [DELIVERY_TYPE]: isUsed ? 'local' : null }),
    });

    if (buyOnlineSelector === MAKE_MODEL_TAB_RADIOS.ONLINE) {
      link = srpLinkBuilderWithParamsConversion(
        {
          ...getInventoryTypeParams(isUsed, isCpo),
          ...params,
        },
        true
      );
    }

    history.replaceState({}, '', buildCurrentUrlWithRandomParam(location));
    venomHistory.push(link);
  };

  toggleBuyOnline = ({ target: { value } }) => {
    this.setState({ buyOnlineSelector: value });
  };

  toggle = ({ target: { value } }) => this.setState({ activeTab: value });

  render() {
    const { activeTab, buyOnlineSelector } = this.state;
    const {
      tracking,
      onSelectionUpdate,
      priceRange: { range, minPrice, maxPrice },
      loanPriceRange: { range: loanRange, maxPayment: loanMaxPayment },
      leasePriceRange: { range: leaseRange, maxPayment: leaseMaxPayment },
      isSmallContainer,
      showRadioButtons,
    } = this.props;

    const tabs = [
      {
        title: PRICE_TAB_IDS.PRICE,
        tabTrackingName: TAB_IDS.VEHICLE_PRICE_TAB,
        trackingId: tracking.tabSelectTracking(TAB_IDS.VEHICLE_PRICE_TAB),
        content: (
          <SearchByVehiclePriceForm
            priceRange={range}
            minPrice={minPrice}
            maxPrice={maxPrice}
            tracking={tracking}
            redirect={this.redirect}
            onSelectionUpdate={onSelectionUpdate}
            isSmallContainer={isSmallContainer}
          />
        ),
      },
      {
        title: PRICE_TAB_IDS.LOAN_PAYMENT,
        tabTrackingName: TAB_IDS.LOAN_PAYMENT_TAB,
        trackingId: tracking.tabSelectTracking(TAB_IDS.LOAN_PAYMENT_TAB),
        content: (
          <SearchByLoanPaymentForm
            tracking={tracking}
            redirect={this.redirect}
            loanPaymentRange={loanRange}
            maxPayment={loanMaxPayment}
            onSelectionUpdate={onSelectionUpdate}
            isSmallContainer={isSmallContainer}
          />
        ),
      },
    ];
    const tabsForNew = [
      ...tabs,
      {
        title: PRICE_TAB_IDS.LEASE_PAYMENT,
        tabTrackingName: TAB_IDS.LEASE_PAYMENT_TAB,
        trackingId: tracking.tabSelectTracking(TAB_IDS.LEASE_PAYMENT_TAB),
        content: (
          <SearchByLeasePaymentForm
            tracking={tracking}
            redirect={this.redirect}
            leasePaymentRange={leaseRange}
            maxPayment={leaseMaxPayment}
            onSelectionUpdate={onSelectionUpdate}
          />
        ),
      },
    ];
    const tabsToRender = this.props.isUsed ? tabs : tabsForNew;

    return (
      <div className="search-by-price-tab">
        {showRadioButtons ? (
          <SearchByBuyOnline
            name="search-by-price"
            buyOnlineSelector={buyOnlineSelector}
            buyOnlineToggle={this.toggleBuyOnline}
            tracking={tracking}
          />
        ) : (
          <RadioList
            name="payment-dropdowns"
            list={tabsToRender}
            checked={activeTab}
            onChange={this.toggle}
            formClassName="mb-1_25 mb-md-0_5 py-0_5 py-md-0"
            formGroupClassName="d-inline-block mr-1_5"
            textClassName="pl-0_25"
          />
        )}

        <TabContent activeTab={activeTab}>
          {tabsToRender.map(({ title, content }) => (
            <TabPane tabId={title} key={`tab-${title}`}>
              {content}
            </TabPane>
          ))}
        </TabContent>
      </div>
    );
  }
}
