import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col, Row, Form } from 'reactstrap';
import { TAB_IDS } from 'client/site-modules/shared/constants/search-by-module';
import { PAYMENT_RANGE } from 'site-modules/shared/components/search-by-module/search-by-module-constants';
import { LEASE_PAYMENT } from 'site-modules/shared/constants/allowed-seo-srp-request-params';
import { validation } from 'site-modules/shared/components/validation/validation';
import { validationHelper } from 'site-modules/shared/components/validation/validation-helper/validation-helper';
import { UpToPaymentDropdown } from 'site-modules/shared/components/payment-dropdowns/up-to-payment-dropdown';
import { SearchByZipAndGo } from 'site-modules/shared/components/search-by-tabs/search-by-tabs-components/search-by-zip-and-go';
import { SearchByFormError } from 'site-modules/shared/components/search-by-tabs/search-by-tabs-components/search-by-form-error';
import { Select } from 'site-modules/shared/components/inventory/select/select';

const VALIDATOR = {
  leasePaymentRange: {
    test: validationHelper.validateFieldLength,
    isRequired: true,
  },
};

export const ERROR_MAP = new Map([['leasePaymentRange', 'Please select a max Lease Payment']]);

export class SearchByLeasePaymentFormUI extends Component {
  static propTypes = {
    tracking: PropTypes.shape({}),
    onSelectionUpdate: PropTypes.func,
    redirect: PropTypes.func.isRequired,
    validateForm: PropTypes.func,
    resetValidation: PropTypes.func,
    errorMap: PropTypes.shape({}),
    leasePaymentRange: PropTypes.string,
    maxPayment: PropTypes.number,
  };

  static defaultProps = {
    tracking: {},
    validateForm: null,
    onSelectionUpdate: null,
    resetValidation: null,
    errorMap: new Map(),
    leasePaymentRange: '',
    maxPayment: 0,
  };

  static getDerivedStateFromProps({ leasePaymentRange }, { isValuesRecievedFromProps }) {
    return leasePaymentRange && !isValuesRecievedFromProps
      ? {
          leasePaymentRange,
          isValuesRecievedFromProps: !isValuesRecievedFromProps,
        }
      : null;
  }

  state = {
    isValuesRecievedFromProps: false,
    isZipChangeInProgress: false,
    isSubmitPending: false,
    leasePaymentRange: '',
    errorMap: this.props.errorMap,
  };

  onZipChangeInProgress = ({ isZipChangeInProgress }) => {
    this.setState({ isZipChangeInProgress }, () => {
      const { isSubmitPending } = this.state;
      if (!isZipChangeInProgress && isSubmitPending) {
        this.validateAndRedirect();
      }
    });
  };

  onSelectLeasePayment = ({ range, maxPayment }) => {
    this.props.resetValidation();
    this.setState({ leasePaymentRange: range }, () => {
      const { onSelectionUpdate } = this.props;
      if (onSelectionUpdate) onSelectionUpdate('leasePriceRange', { range, maxPayment });
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { isZipChangeInProgress } = this.state;
    this.setState({ isSubmitPending: true });

    if (!isZipChangeInProgress) {
      this.validateAndRedirect();
    }
  };

  validateAndRedirect = () => {
    const { leasePaymentRange } = this.state;

    return this.props
      .validateForm({}, { leasePaymentRange }, true)
      .then(() => !this.state.errorMap.size)
      .then(isValid => {
        if (isValid) {
          this.props.redirect({ [LEASE_PAYMENT]: leasePaymentRange });
        } else {
          this.setState({ isSubmitPending: false });
        }
      });
  };

  render() {
    const { errorMap } = this.state;
    const { tracking, maxPayment } = this.props;
    const error = errorMap.get('leasePaymentRange');

    return (
      <Form autoComplete="off" onSubmit={this.handleSubmit}>
        <Row>
          <Col xs={12} md={5} lg={5} className="mb-1 mb-md-0">
            <UpToPaymentDropdown
              onSelect={this.onSelectLeasePayment}
              maxPayment={maxPayment}
              selectClassNames={classNames({ 'with-error': !!error })}
              selectProps={{
                options: PAYMENT_RANGE,
                labelText: null,
                labelClassName: classNames({
                  'text-danger': !!error,
                }),
                isFirstOptionDisabled: false,
                'data-tracking-id': tracking.maxPriceSelectTracking(TAB_IDS.LEASE_PAYMENT_TAB),
                size: 'lg',
                selectComponent: Select,
              }}
            />
            <SearchByFormError error={error} />
          </Col>
          <Col xs={12} md={4} className="d-flex">
            <SearchByZipAndGo
              onZipChangeInProgress={this.onZipChangeInProgress}
              tracking={tracking}
              facetName={TAB_IDS.LEASE_PAYMENT_TAB}
            />
          </Col>
        </Row>
      </Form>
    );
  }
}

export const SearchByLeasePaymentForm = validation(SearchByLeasePaymentFormUI, VALIDATOR, ERROR_MAP);
