import React, { Component } from 'react';
import { pickBy, union, defaultTo } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { DEFAULT_VALIDATORS } from './validators';
import { ERRORS_MAP } from './errors-store';

export function validation(WrappedComponent, validators = {}, errorsMap = ERRORS_MAP) {
  class ValidationUI extends Component {
    static propTypes = {
      venomVersion: PropTypes.string.isRequired,
      pageName: PropTypes.string.isRequired,
    };

    constructor() {
      const mergedValidators = Object.assign({}, DEFAULT_VALIDATORS, validators);
      super();
      this.validationErrors = new Map();
      this.state = {
        validationErrors: this.validationErrors,
        validationClasses: {},
        isSubmitDisabled: false,
        mergedKeys: [...Object.keys(mergedValidators)],
        mergedValidators,
        requiredKeys: Object.keys(pickBy(validators, { isRequired: true })),
      };
    }

    setValidationState = (fieldName, className) => {
      this.setState(prevState => ({
        validationErrors: this.validationErrors,
        isSubmitDisabled: !!prevState.validationErrors.size,
        validationClasses: { ...prevState.validationClasses, [fieldName]: className },
      }));
    };

    handleValidationErrors = (qualifier, name, error) => {
      if (qualifier) {
        this.validationErrors.delete(name);
      } else {
        this.validationErrors.set(name, error);
      }
    };

    validateField = async (name, value, formValues) => {
      const validator = this.state.mergedValidators[name];
      if (!validator || validator.skipValidation) {
        return Promise.resolve();
      }

      const matchValue = validator.matchValue && formValues[validator.matchValue];
      const isValid = await this.executeValidator(validator, value, matchValue, formValues);
      this.handleValidationErrors(isValid, name, validator.errorText || errorsMap.get(name));
      this.setValidationState(name, isValid ? 'has-success' : 'has-danger');
      return Promise.resolve();
    };

    executeValidator = (validator, value, matchValue, formValues) => {
      if (validator.isRequired && !value) {
        return false;
      }
      if (!validator.isRequired && !value) {
        return true;
      }

      const { pageName, venomVersion } = this.props;
      return validator.test(
        {
          fieldValue: value,
          minLength: validator.minLength || matchValue,
          reverseFlag: validator.reverseFlag,
          isAsync: !!(formValues && validator.isAsync),
        },
        { pageName, venomVersion }
      );
    };

    resetValidation = () => {
      this.validationErrors.clear();
      this.setState({
        validationErrors: this.state.validationErrors,
        validationClasses: {},
        isSubmitDisabled: false,
      });
      return true;
    };

    requiredFormValue = (name, changedFormValues, formValues) => changedFormValues[name] || formValues[name];

    changedFormValues = (initialFormValues, formValues) => {
      const changedValues = {};

      Object.keys(initialFormValues).forEach(key => {
        const defaultKey = defaultTo(formValues[key], '').trim();
        if (defaultTo(initialFormValues[key], '').trim() !== defaultKey) {
          changedValues[key] = defaultKey;
        }
      });
      return changedValues;
    };

    validateForm = (initialFormValues, formValues, validateAll = false) => {
      const changedFormValues = validateAll ? { ...formValues } : this.changedFormValues(initialFormValues, formValues);

      const compoundKeys = union(Object.keys(changedFormValues), this.state.requiredKeys);

      const validatedPromises = compoundKeys.map(key =>
        this.validateField(key, this.requiredFormValue(key, changedFormValues, formValues), changedFormValues)
      );

      return Promise.all(validatedPromises);
    };

    updateValidators = updatedValidators => {
      const mergedValidators = Object.assign({}, DEFAULT_VALIDATORS, updatedValidators);
      this.setState({
        mergedKeys: [...Object.keys(mergedValidators)],
        mergedValidators,
        requiredKeys: Object.keys(pickBy(updatedValidators, { isRequired: true })),
      });
    };

    render() {
      return (
        <WrappedComponent
          validateField={this.validateField}
          resetValidation={this.resetValidation}
          validationClasses={this.state.validationClasses}
          errorMap={this.state.validationErrors}
          isSubmitDisabled={this.state.isSubmitDisabled}
          validated={this.state.mergedKeys}
          validateForm={this.validateForm}
          updateValidators={this.updateValidators}
          {...this.props}
        />
      );
    }
  }

  const mapStateToProps = state => ({
    pageName: state.pageContext.page.name,
    venomVersion: state.venomVersion.version,
  });

  return connect(mapStateToProps)(ValidationUI);
}
