import React from 'react';
import PropTypes from 'prop-types';

export function SearchByFormError({ error }) {
  if (!error) {
    return null;
  }
  return <span className="text-danger small">{error}</span>;
}

SearchByFormError.propTypes = {
  error: PropTypes.string,
};

SearchByFormError.defaultProps = {
  error: null,
};
