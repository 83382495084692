import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col, Row, Form } from 'reactstrap';
import { TAB_IDS } from 'client/site-modules/shared/constants/search-by-module';
import { PAYMENT_RANGE } from 'site-modules/shared/components/search-by-module/search-by-module-constants';
import { LOAN_PAYMENT } from 'site-modules/shared/constants/allowed-seo-srp-request-params';
import { validation } from 'site-modules/shared/components/validation/validation';
import { validationHelper } from 'site-modules/shared/components/validation/validation-helper/validation-helper';
import { UpToPaymentDropdown } from 'site-modules/shared/components/payment-dropdowns/up-to-payment-dropdown';
import { SearchByZipAndGo } from 'site-modules/shared/components/search-by-tabs/search-by-tabs-components/search-by-zip-and-go';
import { SearchByFormError } from 'site-modules/shared/components/search-by-tabs/search-by-tabs-components/search-by-form-error';
import { Select } from 'site-modules/shared/components/inventory/select/select';

const VALIDATOR = {
  loanPaymentRange: {
    test: validationHelper.validateFieldLength,
    isRequired: true,
  },
};

export const ERROR_MAP = new Map([['loanPaymentRange', 'Please select a max Loan Payment']]);

export class SearchByLoanPaymentFormUI extends Component {
  static propTypes = {
    tracking: PropTypes.shape({}),
    onSelectionUpdate: PropTypes.func,
    redirect: PropTypes.func.isRequired,
    validateForm: PropTypes.func,
    resetValidation: PropTypes.func,
    errorMap: PropTypes.shape({}),
    loanPaymentRange: PropTypes.string,
    maxPayment: PropTypes.number,
    isSmallContainer: PropTypes.bool,
  };

  static defaultProps = {
    tracking: {},
    onSelectionUpdate: null,
    validateForm: null,
    resetValidation: null,
    errorMap: new Map(),
    loanPaymentRange: '',
    maxPayment: 0,
    isSmallContainer: false,
  };

  static getDerivedStateFromProps({ loanPaymentRange }, { isValuesRecievedFromProps }) {
    return loanPaymentRange && !isValuesRecievedFromProps
      ? {
          loanPaymentRange,
          isValuesRecievedFromProps: !isValuesRecievedFromProps,
        }
      : null;
  }

  state = {
    isValuesRecievedFromProps: false,
    isZipChangeInProgress: false,
    isSubmitPending: false,
    loanPaymentRange: '',
    errorMap: this.props.errorMap,
  };

  onZipChangeInProgress = ({ isZipChangeInProgress }) => {
    this.setState({ isZipChangeInProgress }, () => {
      const { isSubmitPending } = this.state;
      if (!isZipChangeInProgress && isSubmitPending) {
        this.validateAndRedirect();
      }
    });
  };

  onSelectLoanPayment = ({ range, maxPayment }) => {
    this.props.resetValidation();
    this.setState({ loanPaymentRange: range }, () => {
      const { onSelectionUpdate } = this.props;
      if (onSelectionUpdate) onSelectionUpdate('loanPriceRange', { range, maxPayment });
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { isZipChangeInProgress } = this.state;
    this.setState({ isSubmitPending: true });

    if (!isZipChangeInProgress) {
      this.validateAndRedirect();
    }
  };

  validateAndRedirect = () => {
    const { loanPaymentRange } = this.state;

    return this.props
      .validateForm({}, { loanPaymentRange }, true)
      .then(() => !this.state.errorMap.size)
      .then(isValid => {
        if (isValid) {
          this.props.redirect({ [LOAN_PAYMENT]: loanPaymentRange });
        } else {
          this.setState({ isSubmitPending: false });
        }
      });
  };

  render() {
    const { errorMap } = this.state;
    const { tracking, maxPayment, isSmallContainer } = this.props;
    const error = errorMap.get('loanPaymentRange');

    return (
      <Form autoComplete="off" onSubmit={this.handleSubmit}>
        <Row>
          <Col
            xs={12}
            md={isSmallContainer ? 12 : 5}
            lg={isSmallContainer ? 6 : 5}
            className={classNames('mb-1', { 'mb-md-0': !isSmallContainer, 'mb-lg-0': isSmallContainer })}
          >
            <UpToPaymentDropdown
              onSelect={this.onSelectLoanPayment}
              maxPayment={maxPayment}
              selectClassNames={classNames({ 'with-error': !!error })}
              selectProps={{
                options: PAYMENT_RANGE,
                labelText: null,
                labelClassName: classNames({
                  'text-danger': !!error,
                }),
                isFirstOptionDisabled: false,
                'data-tracking-id': tracking.maxPriceSelectTracking(TAB_IDS.LOAN_PAYMENT_TAB),
                size: 'lg',
                selectComponent: Select,
              }}
            />
            <SearchByFormError error={error} />
          </Col>
          <Col xs={12} md={isSmallContainer ? 12 : 4} lg={isSmallContainer ? 6 : 4} className="d-flex">
            <SearchByZipAndGo
              onZipChangeInProgress={this.onZipChangeInProgress}
              tracking={tracking}
              facetName={TAB_IDS.LOAN_PAYMENT_TAB}
            />
          </Col>
        </Row>
      </Form>
    );
  }
}

export const SearchByLoanPaymentForm = validation(SearchByLoanPaymentFormUI, VALIDATOR, ERROR_MAP);
