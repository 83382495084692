import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col, Row, Form } from 'reactstrap';
import { TAB_IDS } from 'client/site-modules/shared/constants/search-by-module';
import { VEHICLE_PRICE_RANGE } from 'site-modules/shared/components/search-by-module/search-by-module-constants';
import { DISPLAY_PRICE } from 'site-modules/shared/constants/allowed-seo-srp-request-params';
import { validation } from 'site-modules/shared/components/validation/validation';
import { validationHelper } from 'site-modules/shared/components/validation/validation-helper/validation-helper';
import { FromToPriceDropdown } from 'site-modules/shared/components/payment-dropdowns/from-to-price-dropdown';
import { SearchByZipAndGo } from 'site-modules/shared/components/search-by-tabs/search-by-tabs-components/search-by-zip-and-go';
import { SearchByFormError } from 'site-modules/shared/components/search-by-tabs/search-by-tabs-components/search-by-form-error';
import { Select } from 'site-modules/shared/components/inventory/select/select';

const VALIDATOR = {
  priceRange: {
    test: validationHelper.validateFieldLength,
    isRequired: true,
  },
};

export const ERROR_MAP = new Map([['priceRange', 'Please select a Min and/or Max Price Value']]);

export class SearchByVehiclePriceFormUI extends Component {
  static propTypes = {
    tracking: PropTypes.shape({}),
    onSelectionUpdate: PropTypes.func,
    redirect: PropTypes.func.isRequired,
    validateForm: PropTypes.func,
    resetValidation: PropTypes.func,
    errorMap: PropTypes.shape({}),
    priceRange: PropTypes.string,
    minPrice: PropTypes.number,
    maxPrice: PropTypes.number,
    isSmallContainer: PropTypes.bool,
  };

  static defaultProps = {
    tracking: {},
    onSelectionUpdate: null,
    validateForm: null,
    resetValidation: null,
    errorMap: new Map(),
    priceRange: '',
    minPrice: 0,
    maxPrice: 0,
    isSmallContainer: false,
  };

  static getDerivedStateFromProps({ priceRange }, { isValuesRecievedFromProps }) {
    return priceRange && !isValuesRecievedFromProps
      ? {
          priceRange,
          isValuesRecievedFromProps: !isValuesRecievedFromProps,
        }
      : null;
  }

  state = {
    isValuesRecievedFromProps: false,
    isZipChangeInProgress: false,
    isSubmitPending: false,
    priceRange: '',
    errorMap: this.props.errorMap,
  };

  onZipChangeInProgress = ({ isZipChangeInProgress }) => {
    this.setState({ isZipChangeInProgress }, () => {
      const { isSubmitPending } = this.state;
      if (!isZipChangeInProgress && isSubmitPending) {
        this.validateAndRedirect();
      }
    });
  };

  onSelectPrice = ({ range, minPrice, maxPrice }) => {
    this.props.resetValidation();
    this.setState({ priceRange: range }, () => {
      const { onSelectionUpdate } = this.props;
      if (onSelectionUpdate) onSelectionUpdate('priceRange', { range, minPrice, maxPrice });
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { isZipChangeInProgress } = this.state;
    this.setState({ isSubmitPending: true });

    if (!isZipChangeInProgress) {
      this.validateAndRedirect();
    }
  };

  validateAndRedirect = () => {
    const { priceRange } = this.state;

    return this.props
      .validateForm({}, { priceRange }, true)
      .then(() => !this.state.errorMap.size)
      .then(isValid => {
        if (isValid) {
          this.props.redirect({ [DISPLAY_PRICE]: priceRange });
        } else {
          this.setState({ isSubmitPending: false });
        }
      });
  };

  render() {
    const { errorMap } = this.state;
    const { tracking, minPrice, maxPrice, isSmallContainer } = this.props;
    const error = errorMap.get('priceRange');
    const selectClassNames = classNames({ 'with-error': !!error });

    return (
      <Form autoComplete="off" onSubmit={this.handleSubmit}>
        <Row>
          <Col
            xs={12}
            md={isSmallContainer ? 12 : 6}
            lg={isSmallContainer ? 7 : 6}
            className={classNames('mb-1', { 'mb-md-0': !isSmallContainer, 'mb-lg-0': isSmallContainer })}
          >
            <FromToPriceDropdown
              onSelect={this.onSelectPrice}
              selectMinClassNames={selectClassNames}
              selectMaxClassNames={selectClassNames}
              minPrice={minPrice}
              maxPrice={maxPrice}
              tracking={{
                minPriceTrackingId: tracking.minPriceSelectTracking(TAB_IDS.VEHICLE_PRICE_TAB),
                maxPriceTrackingId: tracking.maxPriceSelectTracking(TAB_IDS.VEHICLE_PRICE_TAB),
              }}
              selectProps={{
                options: VEHICLE_PRICE_RANGE,
                labelText: null,
                labelClassName: classNames({
                  'text-danger': !!error,
                }),
                isFirstOptionDisabled: false,
                size: 'lg',
                selectComponent: Select,
              }}
            />
            <SearchByFormError error={error} />
          </Col>
          <Col xs={12} md={isSmallContainer ? 12 : 4} lg={isSmallContainer ? 5 : 4} className="d-flex">
            <SearchByZipAndGo
              onZipChangeInProgress={this.onZipChangeInProgress}
              tracking={tracking}
              facetName={TAB_IDS.VEHICLE_PRICE_TAB}
            />
          </Col>
        </Row>
      </Form>
    );
  }
}

export const SearchByVehiclePriceForm = validation(SearchByVehiclePriceFormUI, VALIDATOR, ERROR_MAP);
