import { range } from 'lodash';
import { TAB_IDS } from 'client/site-modules/shared/constants/search-by-module';
import { formatPriceString } from 'site-modules/shared/utils/price-utils';

export const PRICE_TAB_IDS = {
  PRICE: 'Price',
  LOAN_PAYMENT: 'Loan Payment',
  LEASE_PAYMENT: 'Lease Payment',
};

export const TAB_TITLES = {
  [TAB_IDS.MAKE_MODEL_TAB]: 'Make/Model',
  [TAB_IDS.TYPE_TAB]: 'Type',
  [TAB_IDS.PRICE_TAB]: 'Price',
};

/*
 * Loan payments range - from 50 till 1000.
 * Increment by 50.
 * */
const MIN_PAYMENT = 50;
const MAX_PAYMENT = 1000;
const PAYMENT_INCREMENT = 50;

export const PAYMENT_RANGE = range(MIN_PAYMENT, MAX_PAYMENT + PAYMENT_INCREMENT, PAYMENT_INCREMENT).map(value => ({
  value,
  label: `${formatPriceString(value)}`,
}));

/*
 * Vehicle prices range - from 5000 till 100,000.
 * Increment by 5000.
 * */
const MIN_VEHICLE_PRICE = 5000;
const MAX_VEHICLE_PRICE = 100000;
const VEHICLE_PRICE_INCREMENT = 5000;

export const VEHICLE_PRICE_RANGE = range(
  MIN_VEHICLE_PRICE,
  MAX_VEHICLE_PRICE + VEHICLE_PRICE_INCREMENT,
  VEHICLE_PRICE_INCREMENT
).map(value => ({ value, label: `${formatPriceString(value)}` }));
