export const ERRORS_MAP = new Map([
  ['firstName', 'Please correct your name. Special characters cannot be included.'],
  ['lastName', 'Please correct your name. Special characters cannot be included.'],
  ['phone', 'Please enter a valid phone number.'],
  ['zipCode', 'Please enter a valid zip code.'],
  ['street', 'Please enter a valid address.'],
  ['city', 'Please enter a valid city.'],
  ['state', 'Please enter a valid state.'],
]);

export const CHANGE_PASSWORD_ERROR_MAP = new Map([
  ['oldPassword', 'Old password cannot be empty or equal new password.'],
  ['newPassword', 'Your password must be at least 7 characters.'],
  ['confirmPassword', 'Passwords do not match.'],
]);
