import { validationHelper } from './validation-helper/validation-helper';

export const DEFAULT_VALIDATORS = {
  firstName: {
    test: validationHelper.validateName,
    minLength: 1,
  },
  lastName: {
    test: validationHelper.validateName,
    minLength: 1,
  },
  phone: {
    test: validationHelper.validatePhoneNumber,
    minLength: 10,
  },
  zipCode: {
    test: validationHelper.validateZipCode,
  },
  street: {
    test: validationHelper.validateStreet,
    minLength: 2,
  },
  city: {
    test: validationHelper.validateCity,
    minLength: 2,
  },
  state: {
    test: validationHelper.validateFieldLength,
    minLength: 2,
  },
};

export const NEW_USER_PASSWORD_VALIDATORS = {
  newPassword: {
    test: validationHelper.validateFieldLength,
    minLength: 7,
    isRequired: true,
  },
  confirmPassword: {
    test: validationHelper.shouldMatchAndNotEmpty,
    matchValue: 'newPassword',
    isRequired: true,
  },
};

export const PASSWORD_VALIDATORS = {
  oldPassword: {
    test: validationHelper.shouldMatchAndNotEmpty,
    matchValue: 'newPassword',
    reverseFlag: true,
    isRequired: true,
  },
  newPassword: {
    test: validationHelper.validateFieldLength,
    minLength: 7,
    isRequired: true,
  },
  confirmPassword: {
    test: validationHelper.shouldMatchAndNotEmpty,
    matchValue: 'newPassword',
    isRequired: true,
  },
};
