import React from 'react';
import PropTypes from 'prop-types';
import { ToggleButtonBase } from 'site-modules/shared/components/inventory/toggle-button-base/toggle-button-base';

export function TabToggleButton({ isActive, id, ...restButtonProps }) {
  const ariaProps = {
    role: 'tab',
    'aria-selected': isActive,
    'aria-controls': id,
    id: `${id}-tab`,
  };

  return <ToggleButtonBase {...restButtonProps} {...ariaProps} isActive={isActive} />;
}

TabToggleButton.propTypes = {
  isActive: PropTypes.bool,
  id: PropTypes.string.isRequired,
};

TabToggleButton.defaultProps = {
  isActive: false,
};
