import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Spinner } from 'site-modules/shared/components/spinner/spinner';
import { ZipInput } from 'site-modules/shared/components/zip-input/zip-input';
import { validation } from 'site-modules/shared/components/form-validation/validation';
import { Input } from 'site-modules/shared/components/inventory/input/input';

import './search-by-zip-and-go.jsx.scss';

export function SearchByZipAndGo({ tracking, facetName, disabledBtn, showSpinner, onZipChangeInProgress, btnColor }) {
  return (
    <div className="d-flex w-100 search-by-zip-and-go flex-column flex-md-row">
      <ZipInput
        onZipChangeInProgress={onZipChangeInProgress}
        onValidate={validation.validateZip}
        getTracking={tracking.zipInputByTabTracking}
        facetName={facetName}
        inputComponent={Input}
        inputSize=""
        inputProps={{
          inputClassName: 'text-left',
          className: 'search-by-zip-input',
          startAdornment: <span className="icon-location text-gray-40" aria-hidden />,
        }}
      />
      <Button
        type="submit"
        color={btnColor}
        disabled={disabledBtn}
        data-tracking-id={tracking.submitByTabNameTrackingId(facetName)}
        className="search-by-go-btn w-100 font-weight-medium ml-0 ml-md-1 mt-1 mt-md-0"
      >
        {disabledBtn && showSpinner ? <Spinner color="white" /> : <span className="size-16">Go</span>}
      </Button>
    </div>
  );
}

SearchByZipAndGo.propTypes = {
  facetName: PropTypes.string.isRequired,
  tracking: PropTypes.shape({}),
  disabledBtn: PropTypes.bool,
  showSpinner: PropTypes.bool,
  onZipChangeInProgress: PropTypes.func.isRequired,
  btnColor: PropTypes.string,
};

SearchByZipAndGo.defaultProps = {
  disabledBtn: false,
  showSpinner: true,
  tracking: {},
  btnColor: 'blue-50',
};
